.table td,
.table thead th {
  vertical-align: middle;
}

.table:not(.table-borderless) thead th {
  border-bottom: 1px solid #dedede !important;
}

.table {
  &.table-sm {
    tr td,
    tr th {
      padding: 1rem;
    }
  }
  &.table-md {
    tr td,
    tr th {
      padding: 1rem;
    }
  }
  &.table-lg {
    tr td,
    tr th {
      padding: 1.3rem;
    }
  }
}

.dataTable-table {
  @extend .table;
}

// .dataTable-container {
//   overflow-x: auto;
// }

.dataTable-wrapper {
  &.no-footer {
    .dataTable-container {
      border-bottom: none;
    }
  }
}
