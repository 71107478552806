.choices__input {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  width: 100% !important;
}

.choices__list--multiple .choices__item {
  background-color: $primary;
  border: 1px solid $primary;
}

.choices__list--single {
  padding: 0;
}

.choices[data-type*="select-one"]:after {
  display: none;
}
