.navbar-fixed {
  position: fixed;
  background-color: #fff;
}
.navbar {
  height: 90px;
  padding: 1.5rem;
  .navbar-brand img {
    height: 1.5rem;
  }
  .user-menu img {
    width: 39px;
    height: 39px;
  }

  &.navbar-header {
    li {
      display: flex;
      align-items: center;
      &.nav-icon {
        margin-right: 0.4rem;
        .nav-link {
          display: block;
          padding: 0.4rem;
          border-radius: 50%;
          &:hover {
            background-color: $gray-200;
          }
        }
      }
    }
    .dropdown > a {
      color: $gray-600;
      font-weight: 600;

      svg {
        height: 24px;
        width: 24px;
      }
      &:after {
        display: none;
      }
    }
  }
}
