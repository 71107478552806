#main {
  margin-left: 300px;
  padding: 2rem;
  @media screen and (max-width: 1199px) {
    margin-left: 0;
  }
  &.layout-navbar {
    transition: margin 0.5s ease-out;
    padding: 0;
  }
  #main-content {
    padding: 2rem;
  }
}
.page-heading {
  margin: 0 0 1rem;
  h3 {
    font-weight: bold;
  }
}
.page-title-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  h3 {
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .breadcrumb {
    margin-bottom: 0;
  }
}

.page-content {
  margin: 0 0 2rem;
}
