.widget-todo-list-wrapper {
  padding: 0;
  margin: 0;
  .widget-todo-item {
    padding: 0.8rem 2rem 0.8rem 0.8rem;
    list-style: none;

    &:hover {
      background-color: $gray-100;
    }
    .checkbox {
      margin-left: 1rem;
    }
    i,
    svg {
      font-size: 12px;
      cursor: move;
      height: 1rem;
    }
  }
}
