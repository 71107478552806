/*-----------------------------------------------
|   Icons group
-----------------------------------------------*/
.icon-group {
  display: flex;
  .icon-item:not(:last-child) {
    margin-right: map_get($spacers, 2);
  }
}
.icon-item {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-900;
  transition: $transition-base;
  height: 2.75rem;
  width: 2.75rem;
  border: 0;
  font-size: $font-size-sm;
  background: rgba($color: $white, $alpha: 0.8);
  backdrop-filter: blur(4px);
  // box-shadow: $box-shadow-sm;
  @include hover-focus {
    background-color: $gray-200;
  }

  &.icon-item-sm {
    height: 1.875rem;
    width: 1.875rem;
  }

  &.icon-item-lg {
    height: 3.5rem;
    width: 3.5rem;
  }
}
.fontawesome-i2svg-active {
  .icon-indicator {
    opacity: 1;
  }
}

.bi {
  width: 1rem;
  height: 1rem;
  &.bi-middle {
    &:before {
      vertical-align: middle;
    }
  }
  &.bi-sub {
    &:before {
      vertical-align: sub;
    }
  }
}
.stats-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  background-color: black;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: #fff;
    font-size: 1.7rem;
  }
  &.purple {
    background-color: #9694ff;
  }
  &.blue {
    background-color: #57caeb;
  }
  &.red {
    background-color: #ff7976;
  }
  &.green {
    background-color: #5ddab4;
  }
  @media (max-width: 767px) {
    float: left;
    margin-bottom: 0.4rem;
  }
}

.burger-btn {
  display: none;
}
