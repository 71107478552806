/* -------------------------------------------------------------------------- */
/*                                   Borders                                  */
/* -------------------------------------------------------------------------- */

// Safari clipping mask
.overflow-hidden[class*="rounded"] {
  mask-image: radial-gradient($white, $black);
}

.border-top-2 {
  border-top-width: 0.125rem !important;
}
.border-end-2 {
  border-right-width: 0.125rem !important;
}
.border-bottom-2 {
  border-bottom-width: 0.125rem !important;
}
.border-start-2 {
  border-left-width: 0.125rem !important;
}

// Border cleanup
.hr-vertical {
  border-left: 2px solid #cfcfcf;
}

.rounded-subscribe {
  border-radius: initial;

  @include media-breakpoint-up(sm) {
    border-radius: 50rem;
  }
}
