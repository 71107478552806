.anim-rotate {
  animation: rotation 5s infinite linear;
}

.anim-rotate-long {
  animation-duration: 23s;
}

.anim-rotate-short {
  animation-duration: 2s;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.anim-stretching {
  animation: stretching 1s infinite alternate linear;
}

@keyframes stretching {
  from {
    width: 30px;
  }
  to {
    width: 70px;
  }
}
