/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover {
  object-fit: cover;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2 !important;
}
.z-index--1 {
  z-index: -1;
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed {
  border: 1px dashed $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/

.hover-text-decoration-none {
  @include hover-focus {
    text-decoration: none;
  }
}
.resize-none {
  resize: none;
}

.collapsed .collapse-icon {
  transition: $transition-base;
  transform: rotate(0deg);
}
.collapse-icon {
  transition: $transition-base;
  transform: rotate(90deg);
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/

.outline-none {
  outline: none;
  box-shadow: none;
  @include hover-focus {
    outline: none;
    box-shadow: none;
  }
}

/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */

.transition-base {
  transition: $transition-base;
}
.transition-none {
  transition: none;
}

.fsp-75 {
  font-size: 75%;
}

.padding-transition {
  padding: $spacer !important;
}
/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */

.min-w-0 {
  min-width: 0;
}

.icon {
  padding: map_get($spacers, 3);
  border-radius: 15px;
  display: inline-flex;
  background-color: rgba($white, 0.2);
  backdrop-filter: blur(10px);
  border: 2px solid rgba($white, 0.3);

  @each $color, $value in $theme-colors {
    &-#{$color} {
      color: $value;
    }
  }
}

.list-unstyled {
  list-style: none;
}

// Margin
.mt-10 {
  margin-top: 3rem;
}
.mb-10 {
  margin-bottom: 3rem;
}
.my-10 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.mb-24 {
  margin-bottom: 6rem;
}
.my-24 {
  margin-bottom: 6rem;
  margin-top: 6rem;
}

// Opacity
.opacity-50 {
  opacity: 50%;
}

// Padding
.py-4-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

// Font size
.text-sm {
  font-size: 0.875rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-4xl {
  font-size: 2.25rem;
}
.text-6xl {
  font-size: 4rem;
}
.text-black {
  color: #000;
}

// Background
.bg-gradient-ltr {
  background: linear-gradient(to right, #095cde, #53c3f3);
}
@each $key, $value in $theme-colors-light {
  .bg-light-#{$key} {
    background-color: $value;
    color: darken($value, 80%);
  }
}

.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: bold;
}
.font-extrabold {
  font-weight: 800;
}

// Text Width
.text-width-md {
  max-width: 450px;
}

// Text Color
.text-gray-300 {
  color: $gray-300 !important;
}
.text-gray-400 {
  color: $gray-400 !important;
}
.text-gray-500 {
  color: $gray-500 !important;
}
.text-gray-600 {
  color: $gray-600 !important;
}

// Button
.btn-xl {
  padding: 1rem 2rem;
}

// Icon
.icon-mid:before {
  vertical-align: middle;
}

.show-onhover {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}

.show-onhover:hover {
  opacity: 1;
  visibility: visible;
}
