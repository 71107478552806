.media {
  display: flex;
  align-items: center;

  .media-body {
    padding-left: 1rem;
    flex: 1;
  }
}
.email-application .content-area-wrapper {
  border: 1px solid #dfe3e7;
  border-radius: 0.267rem;
  height: calc(100% - 5rem);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;

  .app-content-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 7;
    visibility: hidden;
    opacity: 0;
    border-radius: 0.267rem;
  }
  .app-content-overlay.show {
    visibility: visible;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .sidebar {
    position: inherit;
  }
  .sidebar .email-app-sidebar {
    width: 300px;
    height: calc(100vh - 9rem);
    border-right: 1px solid #dfe3e7;
    border-top-left-radius: 0.267rem;
    border-bottom-left-radius: 0.267rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .sidebar .email-app-sidebar .sidebar-close-icon {
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    cursor: pointer;
    font-size: 1.25rem;
    z-index: 5;
    visibility: hidden;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search .sidebar-toggle i,
  .sidebar .compose-new-mail-sidebar .close-icon i {
    font-size: 1.75rem;
  }
  .sidebar .email-app-sidebar .email-app-menu {
    width: 100%;
    z-index: 3;
    background-color: white;
  }
  .sidebar .email-app-sidebar .email-app-menu .form-group-compose {
    padding: 1px 1.5rem;
    margin-bottom: 0.8rem;
  }
  .sidebar .email-app-sidebar .email-app-menu .form-group-compose .compose-btn {
    box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.6);
    font-weight: 500;
  }
  .sidebar .email-app-sidebar .email-app-menu .sidebar-menu-list {
    padding: 0 1.7rem;
    position: relative;
    height: calc(100% - 6.4rem);
  }
  .sidebar .email-app-sidebar .email-app-menu .sidebar-menu-list a:hover {
    color: #596f88;
  }
  .sidebar .email-app-sidebar .email-app-menu .list-group .list-group-item {
    padding: 0.6rem 0;
    font-weight: 500;
    background-color: transparent;
    border: none;
  }
  .sidebar .email-app-sidebar .email-app-menu .list-group .list-group-item.active {
    color: #5a8dee;
  }
  .sidebar .email-app-sidebar .email-app-menu .list-group .list-group-item.active::before {
    content: "";
    width: 2px;
    height: 30px;
    background-color: #5a8dee;
    position: absolute;
    left: -26px;
  }
  .sidebar .compose-new-mail-sidebar {
    height: calc(100vh - 9rem);
    width: 400px;
    border-radius: 0 0.267rem 0.267rem 0;
    background-color: #fff;
    position: absolute;
    -webkit-transform: translateX(130%);
    -ms-transform: translateX(130%);
    transform: translateX(130%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 8;
    right: 2.15rem;
    bottom: 1px;
  }
  .sidebar .compose-new-mail-sidebar.show {
    -webkit-transform: translateX(8%) translateY(1px);
    -ms-transform: translateX(8%) translateY(1px);
    transform: translateX(8%) translateY(1px);
  }
  .sidebar .compose-new-mail-sidebar .card-footer .btn-send {
    box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.6);
  }
  .sidebar .compose-new-mail-sidebar .close-icon {
    position: absolute;
    top: 18px;
    right: 15px;
    outline: 0;
  }
  .content-right {
    width: calc(100% - 260px);
    background-color: #fff;
  }
  .content-right .content-wrapper {
    padding: 0;
  }
  .content-right .selected-row-bg {
    background-color: #e7edf3 !important;
  }
  .content-right .email-app-list-wrapper .email-app-list .checkbox,
  .content-right .email-app-list-wrapper .email-app-list .checkbox label {
    cursor: pointer;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action {
    padding: 1rem 1.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #dfe3e7;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search {
    margin: 0 0.86rem;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search .sidebar-toggle {
    cursor: pointer;
    margin: 0.5rem 0.99rem 0.5rem 0;
    float: left;
    line-height: 1.1;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search input {
    border-color: #dfe3e7;
    font-family: "IBM Plex Sans", Helvetica, Arial, serif;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search input::-webkit-input-placeholder {
    font-size: 1rem;
    font-family: "IBM Plex Sans", Helvetica, Arial, serif;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search input::-moz-placeholder {
    font-size: 1rem;
    font-family: "IBM Plex Sans", Helvetica, Arial, serif;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search input:-ms-input-placeholder {
    font-size: 1rem;
    font-family: "IBM Plex Sans", Helvetica, Arial, serif;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search input::-ms-input-placeholder {
    font-size: 1rem;
    font-family: "IBM Plex Sans", Helvetica, Arial, serif;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search input::placeholder {
    font-size: 1rem;
    font-family: "IBM Plex Sans", Helvetica, Arial, serif;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-fixed-search .form-control-position {
    top: 3px;
  }
  .content-right .email-app-details .email-detail-header i,
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-left ul li i {
    top: 0;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-pagination-next,
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-right .email-pagination-prev {
    padding: 0.35rem 0.5rem;
    border: 1px solid #dfe3e7;
    margin-left: 0.6rem;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-left ul li .dropdown-toggle::after {
    display: none;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-left ul li .dropdown-menu .dropdown-item .bullet,
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-left ul li .dropdown-menu .dropdown-item i {
    margin-right: 0.5rem;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-action .action-left .list-inline-item:not(:last-child) {
    margin-right: 0.86rem;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list {
    position: relative;
    height: calc(100vh - 13.65rem);
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .ps__rail-y {
    z-index: 6;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper {
    padding: 0;
    margin: 0;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li {
    cursor: pointer;
    -webkit-animation: fadeIn 0.5s linear;
    animation: fadeIn 0.5s linear;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: relative;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li.media {
    padding: 1rem 1.5rem;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
    background-color: #fff;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li.media:hover {
    -webkit-transform: translateY(1px);
    -ms-transform: translateY(1px);
    transform: translateY(1px);
    box-shadow: 0 0 10px 0 rgba(58, 70, 93, 0.25);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 5;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li.media .media-body {
    overflow: hidden;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li .avatar,
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li .avatar img {
    height: 38px;
    width: 38px;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li .avatar img {
    border: 2px solid #fff;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li.mail-read {
    background-color: #f2f4f4;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li.mail-read .list-group-item-text {
    font-weight: 400 !important;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li:not(:first-child) {
    border-top: 1px solid #dfe3e7;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li .user-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li .user-details .list-group-item-text {
    font-size: 1rem;
    font-weight: 500;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li .mail-date {
    color: #828d99;
    font-size: 0.86rem;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li .mail-message {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper li .mail-message p {
    font-size: 0.8rem;
    color: #828d99;
    margin-right: 1rem;
    line-height: 1.75;
  }
  .content-right .email-app-details .email-detail-header .dropdown-menu .dropdown-item .bullet,
  .content-right .email-app-details .email-detail-header .dropdown-menu .dropdown-item i,
  .content-right .email-app-details .email-scroll-area .email-detail-head .collapse-header .card-header .information .dropdown-menu .dropdown-item {
    margin-right: 0.5rem;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper .user-action {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper .user-action .favorite {
    margin-right: 0.5rem;
    color: #c7cfd6;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .users-list-wrapper .user-action .favorite i {
    font-size: 1.3rem;
    line-height: 1.5;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .no-results {
    display: none;
    padding: 1.5rem;
    text-align: center;
  }
  .content-right .email-app-list-wrapper .email-app-list .email-user-list .no-results.show {
    display: block;
  }
  .content-right .email-app-details {
    position: absolute;
    display: block;
    z-index: 6;
    visibility: hidden;
    opacity: 0;
    top: 0;
    width: calc(100% - 260px);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 100%;
    background-color: #f2f4f4;
  }
  .content-right .email-app-details.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    border-left: 1px solid #dfe3e7;
    overflow: hidden;
  }
  .content-right .email-app-details .email-detail-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #dfe3e7;
    padding: 0.85rem 1.5rem 0;
    background-color: #fff;
  }
  .content-right .email-app-details .email-header-right .dropdown-toggle::after,
  .content-right .email-app-details .email-scroll-area .email-detail-head .collapse-header .card-header .information .dropdown-toggle::after {
    display: none;
  }
  .content-right .email-app-details .email-detail-header .go-back {
    cursor: pointer;
  }
  .content-right .email-app-details .email-detail-header .email-pagination-next i,
  .content-right .email-app-details .email-detail-header .email-pagination-prev i {
    top: 2px;
  }
  .content-right .email-app-details .email-detail-header .email-detail-title {
    color: #475f7b;
    font-size: 1.2rem;
    position: relative;
    top: 2px;
  }
  .content-right .email-app-details .email-scroll-area {
    padding: 0 1rem;
    position: relative;
    height: calc(100vh - 13.5rem);
  }
  .content-right .email-app-details .email-scroll-area .email-detail-head {
    padding: 1.5rem 0.5rem 0.75rem;
  }
  .content-right .email-app-details .email-scroll-area .email-detail-head .collapse-header {
    margin-bottom: 1.2rem;
    background-color: transparent;
  }
  .content-right .email-app-details .email-scroll-area .email-detail-head .collapse-header.open {
    background-color: #fff !important;
    box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13) !important;
  }
  .content-right .email-app-details .email-scroll-area .email-detail-head .collapse-header .card-header {
    font-size: 1rem;
    border-color: #dfe3e7;
  }
  .content-right .email-app-details .email-scroll-area .email-detail-head .collapse-header .card-header .information .dropdown-toggle {
    color: #727e8c;
  }
  .content-right .email-app-details .email-scroll-area .email-detail-head .collapse-header .card-header .information .dropdown-menu {
    -webkit-transform: translate3d(-144px, 19px, 0) !important;
    transform: translate3d(-144px, 19px, 0) !important;
  }
  .sidebar-label {
    font-family: Rubik, Helvetica, Arial, serif;
    letter-spacing: 1px;
    color: #828d99;
    margin: 1.8rem 0 0.5rem;
  }
  .attchement-text {
    font-family: "IBM Plex Sans", Helvetica, Arial, serif;
  }
  .action-icon {
    padding: 0.35rem 0.5rem;
    border: 1px solid #dfe3e7;
  }
  .quill-wrapper {
    padding: 1.3rem 1.7rem;
  }
  .quill-wrapper .snow-container {
    position: relative;
  }
  .quill-wrapper .snow-container .ql-snow .ql-tooltip {
    left: 0 !important;
  }
  .quill-wrapper .snow-container .ql-container.ql-snow,
  .quill-wrapper .snow-container .ql-toolbar {
    border: none;
  }
  .quill-wrapper .snow-container .send-btn {
    box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.6);
    font-weight: 500;
    padding: 0.2rem 1rem;
  }
  .quill-wrapper .ql-editor.ql-blank::before {
    left: 0;
  }
  .quill-wrapper .ql-editor {
    min-height: 80px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 1280px) {
  .content-right .email-app-list-wrapper .email-app-list .email-user-list {
    height: calc(100vh - 13.9rem);
  }
}
@media (max-width: 991.98px) {
  .email-application .app-content .content-area-wrapper .email-user-list {
    height: calc(100vh - 17.4rem) !important;
  }
  .email-application .app-content .content-area-wrapper .sidebar-left .email-app-sidebar {
    background-color: #fafbfb;
    height: calc(100vh - 8.98rem);
    -webkit-transform: translateX(-110%);
    -ms-transform: translateX(-110%);
    transform: translateX(-110%);
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
    position: absolute;
    z-index: 8;
    left: -34px;
    top: 1px;
  }
  .email-application .app-content .content-area-wrapper .sidebar-left .email-app-sidebar .sidebar-close-icon {
    visibility: visible;
  }
  .email-application .app-content .content-area-wrapper .sidebar-left.show .email-app-sidebar {
    -webkit-transform: translateX(13%) translateY(-1px);
    -ms-transform: translateX(13%) translateY(-1px);
    transform: translateX(13%) translateY(-1px);
  }
  .email-application .app-content .content-area-wrapper .sidebar-left .compose-new-mail-sidebar.show {
    -webkit-transform: translateX(10.5%) translateY(1px);
    -ms-transform: translateX(10.5%) translateY(1px);
    transform: translateX(10.5%) translateY(1px);
  }
  .email-application .app-content .content-area-wrapper .content-right {
    width: 100%;
  }
  .email-application .app-content .content-area-wrapper .content-right .email-action {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .email-application .app-content .content-area-wrapper .content-right .email-action .action-right {
    margin-bottom: 1rem;
  }
  .email-application .app-content .content-area-wrapper .content-right .email-action .action-right .email-fixed-search {
    margin-left: 0 !important;
  }
  .email-application .app-content .content-area-wrapper .content-right .email-action .action-right .email-fixed-search .sidebar-toggle {
    margin-left: 0;
  }
  .email-application .app-content .content-area-wrapper .content-right .email-app-details {
    width: 100%;
    border-radius: 0.267rem;
  }
}
@media (max-width: 767.98px) {
  .sidebar .compose-new-mail-sidebar {
    width: auto;
  }
}
@media (max-width: 575.98px) {
  .content-right .email-user-list .users-list-wrapper li .user-details .mail-items {
    width: 70%;
    display: inline-grid;
  }
  .content-right .email-user-list .users-list-wrapper li .user-details .mail-meta-item {
    position: absolute;
    right: 1rem;
  }
  .content-right .email-user-list .users-list-wrapper li .user-details .mail-meta-item .mail-date {
    margin: 1rem 0.75rem 0 0;
  }
}
@media screen and (max-width: 436px) {
  .email-app-details .email-header-right {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .email-app-details .email-header-right li {
    margin-right: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    top: 100px;
  }
  75% {
    opacity: 0.5;
    top: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 100px;
  }
  75% {
    opacity: 0.5;
    top: 0;
  }
  100% {
    opacity: 1;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .email-scroll-area .collapse-header .media .media-body {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
  }
}
