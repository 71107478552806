#error {
    height: 100vh;
    background-color: #ebf3ff;
    padding-top: 5rem;
    .img-error {
        width: 100%;
    }
    .error-title {
        font-size: 4rem;
        margin-top: 3rem;
    }
}