// .chat {
//   border-radius: 5px;
//   &.chat-left {
//     .chat-message {
//       background: #5a8dee !important;
//       float: left !important;
//       color: #fff;
//     }
//   }
//   .chat-message {
//     text-align: left !important;
//     float: right !important;
//     margin: 0.2rem 0 1.8rem 0.2rem !important;
//     color: rgb(82, 83, 97);
//     background-color: #fafbfb !important;
//     box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3) !important;
//     padding: 0.75rem 1rem !important;
//     position: relative !important;
//     max-width: calc(100% - 5rem) !important;
//     clear: both !important;
//     word-break: break-word !important;
//     border-radius: 0.267rem !important;
//   }
// }

// .chat-application {
//   .chat-app {
//     height: calc(100vh - 9rem);
//     border: 1px solid #dfe3e7;
//     border-radius: 5px;
//   }
// }
// .chat-app {
//   .chat-app-wrapper {
//   }
//   .chat-app-right {
//     padding-left: 0;
//   }
//   .chat-app-header {
//     .person-name {
//       font-size: 1.2rem;
//     }
//     .person-status {
//     }
//   }
//   .chat-app-body {
//     .left {
//       border-right: 1px solid $gray-200;
//     }
//     ul {
//       padding-left: 0;
//       overflow-y: auto;
//     }
//     .the-contact {
//       list-style: none;
//       padding: 1.5rem 2rem;
//       background-color: $white;
//       border-bottom: 1px solid $gray-200;
//       user-select: none;
//       margin-right: 0;
//       position: relative;

//       &.active {
//         background-color: $primary;
//         .person-name,
//         .message-excerpt {
//           color: #fff;
//         }
//       }
//       .notification-count {
//         position: absolute;
//         right: 1rem;
//         top: 50%;
//         transform: translateY(-50%);
//       }

//       &:hover {
//         cursor: pointer;
//       }

//       &:not(.active):hover {
//         background-color: $gray-200;
//         transition: all 0.1s;
//       }

//       .verified-badge {
//         width: 1.4em;
//         height: 1.4em;
//         padding: 0;
//       }

//       .person-name {
//         font-size: 1.1rem;
//         margin-bottom: 0;
//         font-weight: bold;
//         color: $gray-700;
//         display: flex;
//         align-items: center;
//       }
//       .message-excerpt {
//         font-size: 1rem;
//         color: $gray-600;
//         margin-bottom: 0;
//       }
//     }
//   }
//   .chat-app-footer {
//     position: relative;
//     height: 100%;
//     .input-message-wrapper {
//       position: absolute;
//       bottom: 0;
//       padding: 1rem;
//       background-color: $white;
//       width: 100%;
//       right: 0;
//     }
//   }
//   .messages {
//     display: flex;
//     width: 100%;
//     flex-direction: column;
//     .message {
//       background-color: $gray-300;
//       border-bottom-right-radius: 1rem;
//       border-top-right-radius: 1rem;
//       border-bottom-left-radius: 1rem;
//       padding: 0.8rem;
//       margin: 1rem 1rem;
//       float: left;
//       max-width: 600px;

//       &.message-right {
//         float: right;
//         background-color: $blue-400;
//         color: #fff;

//         border-top-left-radius: 1rem;
//         border-top-right-radius: 0;
//       }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .chat-app {
//     .chat-app-left {
//       right: 100%;
//     }
//     .chat-app-right {
//       position: absolute;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       padding-left: 15px !important;

//       .chat-app-header > div {
//         padding: 0 2rem;
//       }
//     }
//   }
// }

.chat-body {
  height: calc(100vh - 400px);
}
