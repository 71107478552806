#auth {
  height: 100vh;
  overflow-x: hidden;

  #auth-right {
    height: 100%;
    background: url(../images/bg/4853433.jpg), linear-gradient(90deg, #2d499d, #3f5491);
  }
  #auth-left {
    padding: 5rem 8rem;

    .auth-title {
      font-size: 4rem;
      margin-bottom: 1rem;
    }
    .auth-subtitle {
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #a8aebb;
    }
    .auth-logo {
      margin-bottom: 7rem;
      img {
        height: 2rem;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 5rem;
    }
  }
}
