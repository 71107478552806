.dataTable-dropdown,
.dataTable-dropdown label {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
}

.dataTable-dropdown .form-select {
  // padding-left: 1.75rem;
  padding-right: 1.75rem;
  margin-right: 15px;
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background: $primary;
  color: color-contrast($primary);
}
